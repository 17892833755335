import React from "react"

import { Helmet } from "react-helmet"
// import Banner from "../blocs/Banner"
import Header from "../components/headers/header"
import Footer from "../components/footers/footer"

export default function NotFoundPage() {
  return (
    <div className="bg-white">
      <Helmet>
        <title>Erreur 404 - Expertise-Secheresse.fr</title>
        <link rel="canonical" href="https://www.expertise-secheresse.fr/404/" />
      </Helmet>
      <header>
        {/* <Banner /> */}
        <Header />
      </header>
      <main
        className="min-h-full bg-cover bg-top sm:bg-top"
        // style={{
        //   backgroundImage:
        //     'url("https://images.unsplash.com/photo-1545972154-9bb223aac798?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3050&q=80&exp=8&con=-15&sat=-75")',
        // }}
      >
        <div className="max-w-7xl mx-auto px-4 py-16 text-center sm:px-6 sm:py-24 lg:px-8 lg:py-48">
          <p className="text-sm font-semibold text-black text-opacity-50 uppercase tracking-wide">Erreur 404</p>
          <h1 className="mt-2 text-4xl font-extrabold text-gray-500 tracking-tight sm:text-5xl">
            Oh ! Vous êtes perdu !
          </h1>
          <p className="mt-2 text-lg font-medium text-black text-opacity-50">
            La page ne semble pas exister.
          </p>
          <div className="mt-6">
            <a
              href="/"
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-black text-opacity-75 bg-white bg-opacity-75 sm:bg-opacity-25 sm:hover:bg-opacity-50"
            >
              Revenir à l'accueil
            </a>
          </div>
        </div>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}